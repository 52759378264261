@layer components {
  /* BASE TEXTLINK */

  .tw-text-link {
    @apply tw-inline-flex tw-place-items-center tw-gap-0.8 tw-p-0;
    @apply tw-whitespace-nowrap;
    @apply tw-transition-colors;
    @apply tw-text-primary-light-500;
    @apply dark:tw-text-secondary-dark-500;
  }

  .tw-text-link:hover {
    @apply tw-text-primary-light-400;
    @apply dark:tw-text-secondary-dark-400;
  }

  .tw-text-link:active {
    @apply tw-text-primary-light-600;
    @apply dark:tw-text-secondary-dark-500;
  }

  .tw-text-link.tw-text-link-disabled {
    @apply tw-text-neutral-light-500;
    @apply dark:tw-text-neutral-dark-600;
  }

  /* TEXTLINK WITH ICON */

  .tw-text-link-icon > svg {
    @apply tw-w-2 tw-min-w-2 tw-h-2 tw-min-h-2;
    @apply tw-text-current;
  }

  /* TEXTLINK */

  .tw-text-link-s {
    @apply tw-text-link tw-font-subtitle5;
  }

  .tw-text-link-m {
    @apply tw-text-link tw-font-subtitle4;
  }

  .tw-text-link-l {
    @apply tw-text-link tw-font-subtitle3;
  }
}

@layer components {
  /* BASE BUTTON */

  .tw-btn {
    @apply tw-inline-flex tw-place-items-center tw-gap-0.8 tw-align-middle tw-rounded;
    @apply tw-whitespace-nowrap;
    @apply tw-transition-colors;
  }

  .tw-btn.tw-btn-disabled {
    @apply tw-pointer-events-none;
  }

  button.tw-btn {
    @apply tw-border-solid;
  }

  /* BASE SIZED BUTTON */

  .tw-btn-xs {
    @apply tw-btn tw-font-link2 tw-h-3.6 tw-min-h-3.6;
  }

  .tw-btn-s {
    @apply tw-btn tw-font-link2 tw-h-4.4 tw-min-h-4.4;
  }

  .tw-btn-m {
    @apply tw-btn tw-font-link3 tw-h-4.8 tw-min-h-4.8;
  }

  .tw-btn-l {
    @apply tw-btn tw-font-link3 tw-h-5.6 tw-min-h-5.6;
  }

  .tw-btn-xl {
    @apply tw-btn tw-font-link4 tw-h-5.6 tw-min-h-5.6;
  }

  /* BASE FILLED BUTTON */

  .tw-btn-filled-xs {
    @apply tw-btn-xs tw-px-2.4;
  }

  .tw-btn-filled-s {
    @apply tw-btn-s tw-px-2.4;
  }

  .tw-btn-filled-m {
    @apply tw-btn-m tw-px-4;
  }

  .tw-btn-filled-l {
    @apply tw-btn-l tw-px-4;
  }

  .tw-btn-filled-xl {
    @apply tw-btn-xl tw-px-4;
  }

  /* BASE BORDERED BUTTON */

  .tw-btn-bordered-xs {
    @apply tw-btn-xs tw-px-[2.2rem];
  }

  .tw-btn-bordered-s {
    @apply tw-btn-s tw-px-[2.2rem];
  }

  .tw-btn-bordered-m {
    @apply tw-btn-m tw-px-[3.8rem];
  }

  .tw-btn-bordered-l {
    @apply tw-btn-l tw-px-[3.8rem];
  }

  .tw-btn-bordered-xl {
    @apply tw-btn-xl tw-px-[3.8rem];
  }

  /* BASE SIZED BUTTON WITH ICON */

  .tw-btn-xs.tw-btn-icon {
    @apply tw-px-1.6;
  }

  .tw-btn-xs.tw-btn-icon > svg {
    @apply tw-w-1.6 tw-min-w-1.6 tw-h-1.6 tw-min-h-1.6;
  }

  .tw-btn-s.tw-btn-icon {
    @apply tw-px-1.6;
  }

  .tw-btn-s.tw-btn-icon > svg {
    @apply tw-w-2 tw-min-w-2 tw-h-2 tw-min-h-2;
  }

  .tw-btn-m.tw-btn-icon {
    @apply tw-px-2.4;
  }

  .tw-btn-m.tw-btn-icon > svg {
    @apply tw-w-2.4 tw-min-w-2.4 tw-h-2.4 tw-min-h-2.4;
  }

  .tw-btn-l.tw-btn-icon {
    @apply tw-px-2.4;
  }

  .tw-btn-l.tw-btn-icon > svg {
    @apply tw-w-2.4 tw-min-w-2.4 tw-h-2.4 tw-min-h-2.4;
  }

  .tw-btn-xl.tw-btn-icon {
    @apply tw-px-2.4;
  }

  .tw-btn-xl.tw-btn-icon > svg {
    @apply tw-w-2.4 tw-min-w-2.4 tw-h-2.4 tw-min-h-2.4;
  }

  /* BASE PRIMARY */

  .tw-btn-primary {
    @apply tw-bg-primary-light-500 tw-text-neutral-light-100;
    @apply dark:tw-bg-primary-dark-600;
  }

  .tw-btn-primary:hover {
    @apply tw-bg-primary-light-400;
    @apply dark:tw-bg-primary-dark-200 dark:tw-text-primary-dark-700;
  }

  .tw-btn-primary:active {
    @apply tw-bg-primary-light-600;
    @apply dark:tw-bg-primary-light-600 dark:tw-text-neutral-light-100;
  }

  .tw-btn-primary.tw-btn-disabled {
    @apply tw-bg-neutral-light-400 tw-text-neutral-light-500;
    @apply dark:tw-bg-neutral-dark-400 dark:tw-text-neutral-dark-300;
  }

  /* BASE PRIMARY WITH ICON */

  .tw-btn-primary.tw-btn-icon > svg {
    @apply tw-text-neutral-light-100;
  }

  .tw-btn-primary:hover.tw-btn-icon > svg {
    @apply dark:tw-text-primary-dark-700;
  }

  .tw-btn-primary:active.tw-btn-icon > svg {
    @apply dark:tw-text-neutral-light-100;
  }

  .tw-btn-primary.tw-btn-disabled.tw-btn-icon > svg {
    @apply tw-text-neutral-light-500;
    @apply dark:tw-text-neutral-dark-300;
  }

  /* BASE PRIMARY WHITE */

  .tw-btn-primary.tw-btn-white {
    @apply tw-bg-neutral-light-100 tw-text-primary-light-500;
  }

  .tw-btn-primary:hover.tw-btn-white {
    @apply tw-bg-primary-light-500 tw-text-neutral-light-100;
  }

  .tw-btn-primary:active.tw-btn-white {
    @apply tw-bg-primary-light-600 tw-text-neutral-light-100;
  }

  .tw-btn-primary.tw-btn-disabled.tw-btn-white {
    @apply tw-bg-neutral-light-400 tw-text-neutral-light-500;
  }

  /* BASE PRIMARY WHITE WITH ICON */

  .tw-btn-primary.tw-btn-white.tw-btn-icon > svg {
    @apply tw-text-primary-light-500;
  }

  .tw-btn-primary:hover.tw-btn-white.tw-btn-icon > svg {
    @apply tw-text-neutral-light-100;
  }

  .tw-btn-primary:active.tw-btn-white.tw-btn-icon > svg {
    @apply tw-text-neutral-light-100;
  }

  .tw-btn-primary.tw-btn-disabled.tw-btn-white.tw-btn-icon > svg {
    @apply tw-text-neutral-light-500;
  }

  /* PRIMARY */

  .tw-btn-primary-xs {
    @apply tw-btn-primary tw-btn-filled-xs;
  }

  .tw-btn-primary-s {
    @apply tw-btn-primary tw-btn-filled-s;
  }

  .tw-btn-primary-m {
    @apply tw-btn-primary tw-btn-filled-m;
  }

  .tw-btn-primary-l {
    @apply tw-btn-primary tw-btn-filled-l;
  }

  .tw-btn-primary-xl {
    @apply tw-btn-primary tw-btn-filled-xl;
  }

  /* BASE INVERSE */

  .tw-btn-inverse {
    @apply tw-border-[0.2rem];
    @apply tw-bg-transparent tw-border-primary-light-500 tw-text-primary-light-500;
    @apply dark:tw-border-primary-dark-400 dark:tw-text-primary-dark-400;
  }

  .tw-btn-inverse:hover {
    @apply tw-bg-primary-light-400 tw-border-primary-light-400 tw-text-neutral-light-100;
    @apply dark:tw-bg-primary-dark-200 dark:tw-border-primary-dark-200 dark:tw-text-primary-dark-700;
  }

  .tw-btn-inverse:active {
    @apply tw-bg-primary-light-600 tw-border-primary-light-600 tw-text-neutral-light-100;
    @apply dark:tw-bg-primary-light-600 dark:tw-border-primary-light-600 dark:tw-text-neutral-light-100;
  }

  .tw-btn-inverse.tw-btn-disabled {
    @apply tw-bg-transparent tw-border-neutral-light-500 tw-text-neutral-light-500;
    @apply dark:tw-bg-transparent dark:tw-border-neutral-dark-400 dark:tw-text-neutral-dark-400;
  }

  /* BASE INVERSE WITH ICON */

  .tw-btn-inverse.tw-btn-icon > svg {
    @apply tw-text-primary-light-500;
    @apply dark:tw-text-primary-dark-400;
  }

  .tw-btn-inverse:hover.tw-btn-icon > svg {
    @apply tw-text-neutral-light-100;
    @apply dark:tw-text-primary-dark-700;
  }

  .tw-btn-inverse:active.tw-btn-icon > svg {
    @apply dark:tw-text-neutral-light-100;
  }

  .tw-btn-inverse.tw-btn-disabled.tw-btn-icon > svg {
    @apply tw-text-neutral-light-500;
    @apply dark:tw-text-neutral-dark-400;
  }

  /* BASE INVERSE WHITE */

  .tw-btn-inverse.tw-btn-white {
    @apply tw-border-neutral-light-100 tw-text-neutral-light-100;
  }

  .tw-btn-inverse:hover.tw-btn-white {
    @apply tw-bg-neutral-light-100 tw-text-primary-light-500;
  }

  .tw-btn-inverse:active.tw-btn-white {
    @apply tw-bg-neutral-dark-300 tw-border-neutral-dark-300 tw-text-primary-light-500;
  }

  .tw-btn-inverse.tw-btn-disabled.tw-btn-white {
    @apply tw-bg-transparent tw-border-neutral-dark-400 tw-text-neutral-dark-400;
  }

  /* BASE INVERSE WHITE WITH ICON */

  .tw-btn-inverse.tw-btn-white.tw-btn-icon > svg {
    @apply tw-text-neutral-light-100;
  }

  .tw-btn-inverse:hover.tw-btn-white.tw-btn-icon > svg {
    @apply tw-text-primary-light-500;
  }

  .tw-btn-inverse:active.tw-btn-white.tw-btn-icon > svg {
    @apply tw-text-primary-light-500;
  }

  .tw-btn-inverse.tw-btn-disabled.tw-btn-white.tw-btn-icon > svg {
    @apply tw-text-neutral-dark-400;
  }

  /* INVERSE */

  .tw-btn-inverse-xs {
    @apply tw-btn-inverse tw-btn-bordered-xs;
  }

  .tw-btn-inverse-s {
    @apply tw-btn-inverse tw-btn-bordered-s;
  }

  .tw-btn-inverse-m {
    @apply tw-btn-inverse tw-btn-bordered-m;
  }

  .tw-btn-inverse-l {
    @apply tw-btn-inverse tw-btn-bordered-l;
  }

  .tw-btn-inverse-xl {
    @apply tw-btn-inverse tw-btn-bordered-xl;
  }

  /* BASE TERTIARY */

  .tw-btn-tertiary {
    @apply tw-bg-transparent tw-text-neutral-light-700;
    @apply dark:tw-text-neutral-dark-500;
  }

  .tw-btn-tertiary:hover {
    @apply tw-text-primary-light-500;
    @apply dark:tw-text-neutral-light-100;
  }

  .tw-btn-tertiary:active {
    @apply tw-text-primary-light-600;
    @apply dark:tw-text-neutral-light-400;
  }

  .tw-btn-tertiary.tw-btn-disabled {
    @apply tw-text-neutral-light-500;
    @apply dark:tw-text-neutral-light-700;
  }

  /* TERTIARY */

  .tw-btn-tertiary-xs {
    @apply tw-btn-tertiary tw-btn-filled-xs tw-px-1.6;
  }

  .tw-btn-tertiary-s {
    @apply tw-btn-tertiary tw-btn-filled-s tw-px-1.6;
  }

  .tw-btn-tertiary-m {
    @apply tw-btn-tertiary tw-btn-filled-m tw-px-2;
  }

  .tw-btn-tertiary-l {
    @apply tw-btn-tertiary tw-btn-filled-l tw-px-2.4;
  }

  .tw-btn-tertiary-xl {
    @apply tw-btn-tertiary tw-btn-filled-xl tw-px-2.4;
  }

  /* BASE PAYMENT */

  .tw-btn-payment-hover {
    @apply tw-bg-success-500 tw-text-neutral-light-100;
  }

  .tw-btn-payment-active {
    @apply tw-bg-success-600 tw-text-neutral-light-100;
  }

  .tw-btn-payment {
    @apply tw-bg-success-400 tw-text-neutral-light-800;
    @apply dark:tw-bg-success-400;
  }

  .tw-btn-payment:hover {
    @apply tw-btn-payment-hover;
  }

  .tw-btn-payment:active {
    @apply tw-btn-payment-active;
  }

  .tw-btn-payment.tw-btn-disabled {
    @apply tw-bg-neutral-light-400 tw-text-neutral-light-500;
    @apply dark:tw-bg-neutral-dark-500 dark:tw-text-neutral-dark-300;
  }

  /* PAYMENT */

  .tw-btn-payment-xs {
    @apply tw-btn-payment tw-btn-filled-xs;
  }

  .tw-btn-payment-s {
    @apply tw-btn-payment tw-btn-filled-s;
  }

  .tw-btn-payment-m {
    @apply tw-btn-payment tw-btn-filled-m;
  }

  .tw-btn-payment-l {
    @apply tw-btn-payment tw-btn-filled-l;
  }

  .tw-btn-payment-xl {
    @apply tw-btn-payment tw-btn-filled-xl;
  }

  /* BASE PAYMENT INVERSE */

  .tw-btn-payment-inverse {
    @apply tw-border-[0.2rem];
    @apply tw-bg-transparent tw-border-success-500 tw-text-success-500;
    @apply dark:tw-border-success-400 dark:tw-text-success-400;
  }

  .tw-btn-payment-inverse:hover {
    @apply tw-btn-payment-hover;
    @apply tw-border-success-500;
  }

  .tw-btn-payment-inverse:active {
    @apply tw-btn-payment-active;
    @apply tw-border-success-600;
  }

  .tw-btn-payment-inverse.tw-btn-disabled {
    @apply tw-bg-transparent tw-border-neutral-light-500 tw-text-neutral-light-500;
    @apply dark:tw-bg-transparent dark:tw-border-neutral-dark-500 dark:tw-text-neutral-dark-500;
  }

  /* PAYMENT INVERSE */

  .tw-btn-payment-inverse-xs {
    @apply tw-btn-payment-inverse tw-btn-bordered-xs;
  }

  .tw-btn-payment-inverse-s {
    @apply tw-btn-payment-inverse tw-btn-bordered-s;
  }

  .tw-btn-payment-inverse-m {
    @apply tw-btn-payment-inverse tw-btn-bordered-m;
  }

  .tw-btn-payment-inverse-l {
    @apply tw-btn-payment-inverse tw-btn-bordered-l;
  }

  .tw-btn-payment-inverse-xl {
    @apply tw-btn-payment-inverse tw-btn-bordered-xl;
  }

  /* BASE DEACTIVATE */

  .tw-btn-deactivate {
    @apply tw-bg-on-hold-500 tw-text-neutral-light-100;
  }

  .tw-btn-deactivate:hover {
    @apply tw-bg-on-hold-400;
  }

  .tw-btn-deactivate:active {
    @apply tw-bg-on-hold-600;
  }

  .tw-btn-deactivate.tw-btn-disabled {
    @apply tw-bg-neutral-light-400 tw-text-neutral-light-500;
    @apply dark:tw-bg-neutral-dark-500 dark:tw-text-neutral-dark-300;
  }

  /* DEACTIVATE */

  .tw-btn-deactivate-xs {
    @apply tw-btn-deactivate tw-btn-filled-xs;
  }

  .tw-btn-deactivate-s {
    @apply tw-btn-deactivate tw-btn-filled-s;
  }

  .tw-btn-deactivate-m {
    @apply tw-btn-deactivate tw-btn-filled-m;
  }

  .tw-btn-deactivate-l {
    @apply tw-btn-deactivate tw-btn-filled-l;
  }

  .tw-btn-deactivate-xl {
    @apply tw-btn-deactivate tw-btn-filled-xl;
  }

  /* BASE DEACTIVATE INVERSE */

  .tw-btn-deactivate-inverse {
    @apply tw-border-[0.2rem];
    @apply tw-bg-transparent tw-border-on-hold-500 tw-text-on-hold-500;
    @apply dark:tw-border-on-hold-300 dark:tw-text-on-hold-300;
  }

  .tw-btn-deactivate-inverse:hover {
    @apply tw-bg-on-hold-400 tw-border-on-hold-400 tw-text-neutral-light-100;
  }

  .tw-btn-deactivate-inverse:active {
    @apply tw-bg-on-hold-600 tw-border-on-hold-600 tw-text-neutral-light-100;
  }

  .tw-btn-deactivate-inverse.tw-btn-disabled {
    @apply tw-bg-transparent tw-border-neutral-light-500 tw-text-neutral-light-500;
    @apply dark:tw-bg-transparent dark:tw-border-neutral-dark-500 dark:tw-text-neutral-dark-500;
  }

  /* DEACTIVATE INVERSE */

  .tw-btn-deactivate-inverse-xs {
    @apply tw-btn-deactivate-inverse tw-btn-bordered-xs;
  }

  .tw-btn-deactivate-inverse-s {
    @apply tw-btn-deactivate-inverse tw-btn-bordered-s;
  }

  .tw-btn-deactivate-inverse-m {
    @apply tw-btn-deactivate-inverse tw-btn-bordered-m;
  }

  .tw-btn-deactivate-inverse-l {
    @apply tw-btn-deactivate-inverse tw-btn-bordered-l;
  }

  .tw-btn-deactivate-inverse-xl {
    @apply tw-btn-deactivate-inverse tw-btn-bordered-xl;
  }

  /* BASE DELETE */

  .tw-btn-delete {
    @apply tw-bg-alert-500 tw-text-neutral-light-100;
    @apply dark:tw-bg-alert-400;
  }

  .tw-btn-delete:hover {
    @apply tw-bg-alert-400;
    @apply dark:tw-bg-alert-300;
  }

  .tw-btn-delete:active {
    @apply tw-bg-alert-600;
    @apply dark:tw-bg-alert-600;
  }

  .tw-btn-delete.tw-btn-disabled {
    @apply tw-bg-neutral-light-400 tw-text-neutral-light-500;
    @apply dark:tw-bg-neutral-dark-500 dark:tw-text-neutral-dark-300;
  }

  /* DELETE */

  .tw-btn-delete-xs {
    @apply tw-btn-delete tw-btn-filled-xs;
  }

  .tw-btn-delete-s {
    @apply tw-btn-delete tw-btn-filled-s;
  }

  .tw-btn-delete-m {
    @apply tw-btn-delete tw-btn-filled-m;
  }

  .tw-btn-delete-l {
    @apply tw-btn-delete tw-btn-filled-l;
  }

  .tw-btn-delete-xl {
    @apply tw-btn-delete tw-btn-filled-xl;
  }

  /* BASE DELETE INVERSE */

  .tw-btn-delete-inverse {
    @apply tw-border-[0.2rem];
    @apply tw-bg-transparent tw-border-alert-500 tw-text-alert-500;
    @apply dark:tw-border-alert-300 dark:tw-text-alert-300;
  }

  .tw-btn-delete-inverse:hover {
    @apply tw-bg-alert-400 tw-border-alert-400 tw-text-neutral-light-100;
    @apply dark:tw-bg-alert-300 dark:tw-border-alert-300;
  }

  .tw-btn-delete-inverse:active {
    @apply tw-bg-alert-600 tw-border-alert-600 tw-text-neutral-light-100;
    @apply dark:tw-bg-alert-600 dark:tw-border-alert-600;
  }

  .tw-btn-delete-inverse.tw-btn-disabled {
    @apply tw-bg-transparent tw-border-neutral-light-500 tw-text-neutral-light-500;
    @apply dark:tw-bg-transparent dark:tw-border-neutral-dark-500 dark:tw-text-neutral-dark-500;
  }

  /* DELETE INVERSE */

  .tw-btn-delete-inverse-xs {
    @apply tw-btn-delete-inverse tw-btn-bordered-xs;
  }

  .tw-btn-delete-inverse-s {
    @apply tw-btn-delete-inverse tw-btn-bordered-s;
  }

  .tw-btn-delete-inverse-m {
    @apply tw-btn-delete-inverse tw-btn-bordered-m;
  }

  .tw-btn-delete-inverse-l {
    @apply tw-btn-delete-inverse tw-btn-bordered-l;
  }

  .tw-btn-delete-inverse-xl {
    @apply tw-btn-delete-inverse tw-btn-bordered-xl;
  }
}

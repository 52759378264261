@layer utilities {
  #tw-crehana-loader {
    display: grid;
    grid-template-areas:
      'a . b'
      'c d e'
      'f g h';
  }

  #tw-loader-item-a {
    grid-area: a;
    animation-name: tw-loader-item-animation-a;
    animation-iteration-count: infinite;
  }
  #tw-loader-item-b {
    grid-area: b;
    animation-name: tw-loader-item-animation-b;
    animation-iteration-count: infinite;
  }
  #tw-loader-item-c {
    grid-area: c;
    animation-name: tw-loader-item-animation-c;
    animation-iteration-count: infinite;
  }
  #tw-loader-item-d {
    grid-area: d;
    animation-name: tw-loader-item-animation-d;
    animation-iteration-count: infinite;
  }
  #tw-loader-item-e {
    grid-area: e;
    animation-name: tw-loader-item-animation-e;
    animation-iteration-count: infinite;
  }
  #tw-loader-item-f {
    grid-area: f;
    animation-name: tw-loader-item-animation-f;
    animation-iteration-count: infinite;
  }
  #tw-loader-item-g {
    grid-area: g;
    animation-name: tw-loader-item-animation-g;
    animation-iteration-count: infinite;
  }
  #tw-loader-item-h {
    grid-area: h;
    animation-name: tw-loader-item-animation-h;
    animation-iteration-count: infinite;
  }

  @keyframes tw-loader-item-animation-a {
    0% {
      animation-timing-function: steps(1, end);
      opacity: 1;
    }
    20% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    45% {
      animation-timing-function: steps(1, end);
      opacity: 1;
    }
    85% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    100% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
  }

  @keyframes tw-loader-item-animation-b {
    0% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    5% {
      animation-timing-function: steps(1, end);
      opacity: 1;
    }
    80% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    100% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
  }

  @keyframes tw-loader-item-animation-c {
    0% {
      animation-timing-function: steps(1, end);
      opacity: 1;
    }
    10% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    100% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
  }

  @keyframes tw-loader-item-animation-d {
    0% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    35% {
      animation-timing-function: steps(1, end);
      opacity: 1;
    }
    70% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    100% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
  }

  @keyframes tw-loader-item-animation-e {
    0% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    5% {
      animation-timing-function: steps(1, end);
      opacity: 1;
    }
    15% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    30% {
      animation-timing-function: steps(1, end);
      opacity: 1;
    }
    75% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    100% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
  }

  @keyframes tw-loader-item-animation-f {
    0% {
      animation-timing-function: steps(1, end);
      opacity: 1;
    }
    60% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    100% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
  }

  @keyframes tw-loader-item-animation-g {
    0% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    25% {
      animation-timing-function: steps(1, end);
      opacity: 1;
    }
    40% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    100% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
  }

  @keyframes tw-loader-item-animation-h {
    0% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    5% {
      animation-timing-function: steps(1, end);
      opacity: 1;
    }
    65% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
    100% {
      animation-timing-function: steps(1, end);
      opacity: 0;
    }
  }
}

.tw-raw-html h1 {
  @apply tw-font-h3;
}
.tw-raw-html h2 {
  @apply tw-font-subtitle1;
}
.tw-raw-html p {
  @apply tw-font-body3;
  @apply tw-py-0.1;
}
.tw-raw-html strong {
  @apply tw-font-semibold;
  @apply tw-whitespace-pre-wrap;
}
.tw-raw-html span {
  @apply tw-whitespace-pre-wrap;
}

.tw-raw-html em {
  @apply tw-italic;
  @apply tw-whitespace-pre-wrap;
}

.tw-raw-html u {
  @apply tw-underline;
}

.tw-raw-html i {
  @apply tw-italic;
}

.tw-raw-html b {
  @apply tw-font-semibold;
}

.tw-raw-html li {
  @apply tw-font-body4;
  @apply tw-list-disc;
  @apply tw-ml-2.4;
}

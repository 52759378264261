@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('https://static.crehana.com/static/fonts/compass/poppins/semibold/poppins-v20-latin-600.72993dddf88a.woff2')
      format('woff2'),
    url('https://static.crehana.com/static/fonts/compass/poppins/semibold/poppins-v20-latin-600.d5a7daaedf64.woff')
      format('woff'),
    url('https://static.crehana.com/static/fonts/compass/poppins/semibold/poppins-v20-latin-600.8f07cdf20df4.ttf')
      format('truetype'),
    url('https://static.crehana.com/static/fonts/compass/poppins/semibold/Poppins-v20-latin-600.b0b3d360d13a.otf')
      format('opentype'),
    url('https://static.crehana.com/static/fonts/compass/poppins/semibold/poppins-v20-latin-600.18b837b5ba21.eot?#iefix')
      format('embedded-opentype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://static.crehana.com/static/fonts/compass/poppins/bold/Poppins-Bold.d9d4f92e841a.woff2')
      format('woff2'),
    url('https://static.crehana.com/static/fonts/compass/poppins/bold/Poppins-Bold.ce88cd7ef203.woff')
      format('woff'),
    url('https://static.crehana.com/static/fonts/compass/poppins/bold/Poppins-Bold.08c20a487911.ttf')
      format('truetype'),
    url('https://static.crehana.com/static/fonts/compass/poppins/bold/Poppins-Bold.23c95dd87f5b.otf')
      format('opentype'),
    url('https://static.crehana.com/static/fonts/compass/poppins/bold/Poppins-Bold.c4a2b747bcfc.eot?#iefix')
      format('embedded-opentype');
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://static.crehana.com/static/fonts/compass/poppins/regular/poppins-v20-latin-regular.9212f6f9860f.woff2')
      format('woff2'),
    url('https://static.crehana.com/static/fonts/compass/poppins/regular/poppins-v20-latin-regular.f8ed328094e3.woff')
      format('woff'),
    url('https://static.crehana.com/static/fonts/compass/poppins/regular/poppins-v20-latin-regular.891f6ba4349c.ttf')
      format('truetype'),
    url('https://static.crehana.com/static/fonts/compass/poppins/regular/poppins-v20-latin-regular.5ec8a71ac396.otf')
      format('opentype'),
    url('https://static.crehana.com/static/fonts/compass/poppins/regular/poppins-v20-latin-regular.9bb84f43a127.eot?#iefix')
      format('embedded-opentype');
}

.text-wrap {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap;
  overflow: hidden;
}

.iconWrapper-grey > svg path {
  fill: #181b32;
}

.iconWrapper-lightblue > svg path {
  fill: #03cad2;
}

.iconWrapper-white > svg path {
  fill: #fff;
}

.path-current-color,
.path-current-color > path {
  fill: currentColor;
}

.scale-in-hover {
  transition: transform 0.3s ease-in-out;
}

.scale-in-hover:hover {
  transform: scale(1.161);
}

.zIndex-200 {
  z-index: 200;
}

.zIndex-300 {
  z-index: 300;
}

.zIndex-50 {
  z-index: 50;
}

.fixTopBanner {
  transition: transform 0.5s ease;
}

.creh-container {
  margin: 0 auto;
  max-width: 480px;
  width: calc(100% - 32px);
}

@media (min-width: 768px) {
  .creh-container {
    max-width: 1024px;
    width: calc(100% - 128px);
  }
}

@media (min-width: 1280px) {
  .creh-container {
    max-width: 1140px;
    width: 1140px;
  }
}

.creh-containerv2 {
  width: 100%;
  padding: 0 20px;
}

@media (min-width: 768px) {
  .creh-containerv2 {
    padding: 0 36px;
  }
}

@media (min-width: 1024px) {
  .creh-containerv2 {
    padding: 0 40px;
    max-width: 1240px;
    margin: 0 auto;
  }
}

.main-menu-padding {
  padding-top: 52px;
}

@media (min-width: 1140px) {
  .main-menu-padding {
    padding-top: 72px;
  }
}

* {
  box-sizing: border-box;
  list-style: none;
}

/*! purgecss start ignore */
.react-tour-mask {
  color: rgba(44, 47, 72, 0.5);
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    -webkit-border-radius: 5px;
    background-color: transparent;
    border-radius: 5px;
    height: 10px;
    transition: background-color 0.2s linear;
    width: 10px;
  }
  ::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
    transition: background-color 0.2s linear;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    background: #aaa;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: background-color 0.2s linear;
  }
  ::-webkit-scrollbar-thumb:active {
    -webkit-border-radius: 5px;
    background: rgba(0, 0, 0, 0.4);
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:vertical {
    min-height: 10px;
  }
  ::-webkit-scrollbar-thumb:horizontal {
    min-width: 10px;
  }
}

#nprogress .bar {
  background: #4b22f4;
}

#nprogress .peg {
  box-shadow: 0 0 10px #4b22f4, 0 0 5px #4b22f4;
}

#nprogress .spinner-icon {
  border-top-color: #4b22f4;
  border-left-color: #4b22f4;
}
/*! purgecss end ignore */

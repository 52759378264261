@layer components {
  .tw-input {
    @apply tw-transition-colors;
    @apply tw-font-body4 placeholder:tw-font-body4;
    @apply tw-line-clamp-1 tw-text-neutral-light-800 dark:tw-text-neutral-light-100;
    @apply tw-border tw-border-solid tw-border-neutral-light-500 hover:tw-border-primary-light-300 focus-within:tw-border-primary-light-500 invalid:tw-border-alert-400;
    @apply tw-items-center tw-gap-0.8 tw-rounded tw-px-1.6 tw-h-4 tw-outline-none tw-w-full;
    @apply dark:tw-bg-neutral-dark-700;
    @apply dark:tw-border-neutral-dark-600 hover:dark:tw-border-primary-dark-300 focus-within:dark:tw-border-primary-dark-300 invalid:dark:tw-border-alert-300;
    @apply aria-expanded:tw-border-primary-light-500 aria-expanded:dark:tw-border-primary-dark-300;
    @apply disabled:tw-border-neutral-light-400 dark:disabled:tw-border-neutral-dark-500 disabled:tw-bg-neutral-light-400 dark:disabled:tw-bg-neutral-dark-500 disabled:tw-pointer-events-none;
    @apply disabled:tw-text-neutral-light-500 dark:disabled:tw-text-neutral-dark-600 disabled:placeholder:tw-text-neutral-light-500 dark:disabled:placeholder:tw-text-neutral-dark-600 disabled:data-[placeholder]:tw-text-neutral-light-500 dark:disabled:data-[placeholder]:tw-text-neutral-dark-600;
    @apply data-[placeholder]:tw-text-neutral-light-700 tw-placeholder-neutral-light-700 dark:data-[placeholder]:tw-text-neutral-dark-500 dark:tw-placeholder-neutral-dark-500;
    display: inline-flex;
  }
}

/**
 * Keep in mind that this file is not fully-compiled when publishing the package to npm.
 * That's because this file must be used as a configuration file rather than a css file.
 * Import it in your app and then use tailwindcss or postcss to compile/get the full css
*/
@import './fonts/fonts.css';

/** Base components */
@import 'tailwindcss/base.css';
@import './base/base.css';
@import './base/colors.base.css';
@import './base/typography.base.css';

/** Tailwindcss components */
@import 'tailwindcss/components.css';
@import './components/grid.components.css';
@import './components/typography.components.css';
@import './components/buttons.components.css';
@import './components/textlink.components.css';
@import './components/input.components.css';

/** utilities */
@import 'tailwindcss/utilities.css';
@import './utilities/crehana-loader.css';
@import './utilities/skeleton.css';
@import './utilities/raw-html.css';

@layer components {
  .tw-font-main-title {
    @apply tw-font-pop tw-text-3.6 tw-leading-4 tw-tracking-0;
  }

  .tw-font-h1 {
    @apply tw-font-pop tw-text-3.6 tw-leading-4 tw-tracking-0;
  }

  .tw-font-h2 {
    @apply tw-font-pop tw-text-2.8 tw-leading-3.2 tw-tracking-0;
  }

  .tw-font-h3 {
    @apply tw-font-pop tw-text-2.4 tw-leading-3.2 tw-tracking-0;
  }

  .tw-font-h4 {
    @apply tw-font-pop tw-text-2 tw-leading-2.4 tw-tracking-0;
  }

  .tw-font-h5 {
    @apply tw-font-pop tw-text-2 tw-leading-2.4 tw-tracking-0;
  }

  .tw-font-subtitle1 {
    @apply tw-font-pop tw-font-semibold tw-text-1.8 tw-leading-2.4 tw-tracking-1;
  }

  .tw-font-subtitle2 {
    @apply tw-font-pop tw-font-semibold tw-text-1.6 tw-leading-2 tw-tracking-1;
  }

  .tw-font-subtitle3 {
    @apply tw-font-pop tw-font-semibold tw-text-1.6 tw-leading-2 tw-tracking-1;
  }

  .tw-font-subtitle4 {
    @apply tw-font-pop tw-font-semibold tw-text-1.4 tw-leading-1.8 tw-tracking-0;
  }

  .tw-font-subtitle5 {
    @apply tw-font-pop tw-font-semibold tw-text-1.4 tw-leading-1.8 tw-tracking-0;
  }

  .tw-font-subtitle6 {
    @apply tw-font-pop tw-font-semibold tw-text-1.2 tw-leading-1.8 tw-tracking-0;
  }

  /* Paragraph */
  .tw-font-body1 {
    @apply tw-font-pop tw-text-1.8 tw-leading-2.8 tw-tracking-1;
  }

  .tw-font-body2 {
    @apply tw-font-pop tw-text-1.6 tw-leading-2.4 tw-tracking-1;
  }

  .tw-font-body3 {
    @apply tw-font-pop tw-text-1.6 tw-leading-2.4 tw-tracking-1;
  }

  .tw-font-body4 {
    @apply tw-font-pop tw-text-1.4 tw-leading-2.2 tw-tracking-1;
  }

  .tw-font-body5 {
    @apply tw-font-pop tw-text-1.2 tw-leading-1.8 tw-tracking-0;
  }

  /** Other cases */
  .tw-font-link1 {
    @apply tw-font-pop tw-font-semibold tw-text-1.2 tw-leading-1.4 tw-tracking-2;
  }

  .tw-font-link2 {
    @apply tw-font-pop tw-font-semibold tw-text-1.4 tw-leading-2.1 tw-tracking-2;
  }

  .tw-font-link3 {
    @apply tw-font-pop tw-font-semibold tw-text-1.6 tw-leading-2.4 tw-tracking-2;
  }

  .tw-font-label1 {
    @apply tw-font-pop tw-font-semibold tw-uppercase tw-text-1.2 tw-leading-1.4 tw-tracking-15;
  }

  .tw-font-label2 {
    @apply tw-font-pop tw-font-semibold tw-uppercase tw-text-1.4 tw-leading-1.8 tw-tracking-15;
  }

  .tw-font-caption {
    @apply tw-font-pop tw-font-semibold tw-text-1.2 tw-leading-1.6 tw-tracking-0;
  }

  /** Prices */
  .tw-font-currency {
    @apply tw-font-pop tw-text-2 tw-leading-2.8 tw-tracking-0;
  }

  .tw-font-discount {
    @apply tw-font-pop tw-text-1.4 tw-leading-1.8 tw-tracking-0;
  }

  .tw-font-currency-s {
    @apply tw-font-pop tw-text-2 tw-leading-2.8 tw-tracking-0;
  }

  .tw-font-discount-s {
    @apply tw-font-pop tw-text-1.2 tw-leading-1.4 tw-tracking-1;
  }

  .tw-font-currency-xs {
    @apply tw-font-pop tw-text-1.6 tw-leading-2 tw-tracking-0;
  }

  .tw-font-discount-xs {
    @apply tw-font-pop tw-text-1.2 tw-leading-1.6 tw-tracking-1;
  }

  @media screen(sm) {
    .tw-font-main-title {
      @apply tw-text-5.2 tw-leading-6.4 tw-tracking-0;
    }

    .tw-font-h1 {
      @apply tw-text-5.2 tw-leading-6.4 tw-tracking-0;
    }

    .tw-font-h2 {
      @apply tw-text-4.4 tw-leading-5.2 tw-tracking-0;
    }

    .tw-font-h3 {
      @apply tw-text-3.6 tw-leading-4.4 tw-tracking-0;
    }

    .tw-font-h4 {
      @apply tw-text-2.8 tw-leading-3.2 tw-tracking-0;
    }

    .tw-font-h5 {
      @apply tw-text-2 tw-leading-2.8 tw-tracking-1;
    }

    .tw-font-subtitle1 {
      @apply tw-text-2.4 tw-leading-3 tw-tracking-1;
    }

    .tw-font-subtitle2 {
      @apply tw-text-2 tw-leading-2.5 tw-tracking-1;
    }

    .tw-font-subtitle3 {
      @apply tw-text-1.8 tw-leading-2.4 tw-tracking-1;
    }

    .tw-font-subtitle4 {
      @apply tw-text-1.6 tw-leading-2 tw-tracking-0;
    }

    /* Paragraph */
    .tw-font-body1 {
      @apply tw-text-2 tw-leading-3 tw-tracking-1;
    }

    .tw-font-body2 {
      @apply tw-text-1.8 tw-leading-2.8 tw-tracking-1;
    }

    .tw-font-body3 {
      @apply tw-text-1.6 tw-leading-2.4 tw-tracking-0;
    }

    /* .tw-font-body4 {
      @apply tw-text-1.6 tw-leading-2.4 tw-tracking-0;
    } */

    /** Other cases */
    .tw-font-link1 {
      @apply tw-text-1.2 tw-leading-1.8 tw-tracking-0;
    }

    .tw-font-link2 {
      @apply tw-text-1.4 tw-leading-2 tw-tracking-0;
    }

    .tw-font-link3 {
      @apply tw-text-1.6 tw-leading-2.4 tw-tracking-0;
    }

    .tw-font-link4 {
      @apply tw-font-pop tw-font-semibold tw-text-1.8 tw-leading-2.4 tw-tracking-0;
    }

    .tw-font-label1 {
      @apply tw-text-1.2 tw-leading-1.8 tw-tracking-15;
    }

    .tw-font-label2 {
      @apply tw-text-1.2 tw-leading-2 tw-tracking-15;
    }

    .tw-font-caption {
      @apply tw-text-1.2 tw-leading-1.8 tw-tracking-0;
    }

    /** Prices */
    .tw-font-currency {
      @apply tw-text-2.8 tw-leading-3.6 tw-tracking-0;
    }

    .tw-font-discount {
      @apply tw-text-1.6 tw-leading-2 tw-tracking-0;
    }

    .tw-font-currency-s {
      @apply tw-text-2.4 tw-leading-2.8 tw-tracking-0;
    }

    .tw-font-discount-s {
      @apply tw-text-1.4 tw-leading-1.8 tw-tracking-0;
    }
  }
}

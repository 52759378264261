@layer utilities {
  @keyframes shineKeyframesBefore {
    0% {
      transform: translateX(-160%);
    }

    100% {
      transform: translateX(160%);
    }
  }

  @keyframes shineKeyframesAfter {
    0% {
      transform: translateX(-120%);
    }

    80% {
      transform: translateX(400%);
    }

    100% {
      transform: translateX(400%);
    }
  }

  .tw-skeleton {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .tw-skeleton::before {
    content: '';
    background-color: white;
    position: absolute;
    left: 0;
    opacity: 60%;
    filter: blur(40px);
    width: 70%;
    height: 140%;
    animation-name: shineKeyframesBefore;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
  }

  .tw-skeleton::after {
    content: '';
    background-color: white;
    position: absolute;
    left: 0;
    opacity: 100%;
    filter: blur(25px);
    width: 30%;
    height: 140%;
    animation-name: shineKeyframesAfter;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
  }

  .tw-dark .tw-skeleton::before {
    background-color: #292c47;
  }

  .tw-dark .tw-skeleton::after {
    background-color: #292c47;
  }
}

@layer components {
  .tw-container {
    max-width: 343px;
    width: 100%;
    @apply tw-grid tw-grid-cols-12 tw-mx-auto tw-gap-x-0.8 tw-gap-y-1.6;
  }

  @media screen(sm) {
    .tw-container {
      max-width: 704px;
      @apply tw-gap-x-1.6 tw-gap-y-2.4;
    }
  }

  @media screen(md) {
    .tw-container {
      max-width: 840px;
      @apply tw-gap-2.4;
    }
  }

  @media screen(lg) {
    .tw-container {
      max-width: 1152px;
    }
  }

  @media screen(xl) {
    .tw-container {
      max-width: 1368px;
    }
  }
}
